import { Button, Col, Divider, Modal, Row } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const ModalAgentReliquat = ({ open, onOk, reliquat, viewportWidth }) => {
  const { t } = useTranslation();

  // console.log("reliquat: ", reliquat);

  return (
    <Modal
      title={t("RELIQUAT TOTAL")}
      width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}
      open={open}
      onOk={onOk}
      onCancel={onOk}
      closable={true}
      footer={[
        <Button key="back" onClick={onOk}>
          {t("Fermer")}
        </Button>,
      ]}>
      <Row>
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          Article
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          {t("Qté")}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
          Client
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
          Date
        </Col>
      </Row>
      <Divider style={{ marginTop: "12px" }} />
      <Row>
        {reliquat?.length > 0
          ? reliquat.map((b) => {
              if (b !== null) {
                return (
                  <Fragment key={b?.Id}>
                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                      {b?.Designation}
                      {/* {b.Designation.split("-")[0]} */}
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      {b?.Quantite}
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
                      {b?.Intitule}
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
                      {
                        new Date(parseInt(b?.DateCreation.split("(")[1].split(")")[0]))
                          .toLocaleString()
                          .split(" ")[0]
                      }
                    </Col>
                    <Divider />
                  </Fragment>
                );
              }
            })
          : ""}
      </Row>
    </Modal>
  );
};

export default ModalAgentReliquat;
