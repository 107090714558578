import { Button, Col, Divider, Modal, Row } from "antd";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import MainContext from "../../contexts/MainContext";

const ModalVolume = ({ articlesVolume, open, onOk }) => {
  const { viewportWidth } = useContext(MainContext);
  const { t } = useTranslation();

  return (
    <Modal
      title={t("VOLUME")}
      open={open}
      onOk={onOk}
      closable={true}
      onCancel={onOk}
      footer={[
        <Button key="back" onClick={onOk}>
          {t("Fermer")}
        </Button>,
      ]}
      width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}
    >
      <Row>
        <Col xs={16} sm={16} md={16} lg={16} xl={16}>
          {articlesVolume && articlesVolume.length > 1 ? "Articles" : "Article"}
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "right" }}>
          {t("Qté")}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
          Date
        </Col>
      </Row>
      <Divider style={{ marginTop: "12px" }} />
      <Row>
        {articlesVolume && articlesVolume.length
          ? articlesVolume?.map((article) => (
              <Fragment key={article?.Id}>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>{article.Designation}</p>
                  </div>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  <div style={{ textAlign: "right" }}>
                    <p>{article.Quantite}</p>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div style={{ textAlign: "right" }}>
                    <p>
                      {
                        new Date(
                          parseInt(article.Date.split("(")[1].split(")")[0])
                        )
                          .toLocaleString()
                          .split(" ")[0]
                      }
                    </p>
                  </div>
                </Col>
                <Divider />
              </Fragment>
            ))
          : articlesVolume?.map((art) => art.Designation)}
      </Row>
    </Modal>
  );
};

export default ModalVolume;
