import { createContext, useState } from "react";

const SearchContext = createContext({});

const Provider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [searchTextProducts, setSearchTextProducts] = useState();

  const saveFilters = (tableId, filterData) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [tableId]: filterData,
    }));
  };

  return (
    <SearchContext.Provider
      value={{
        filters,
        saveFilters,
        searchTextProducts,
        setSearchTextProducts,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export { Provider };
export default SearchContext;
