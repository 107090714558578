import { Button, Col, Divider, Modal, Row } from "antd";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import MainContext from "../../contexts/MainContext";

const ModalReliquat = ({ articlesReliquat, open, onOk }) => {
  const { viewportWidth } = useContext(MainContext);
  const { t } = useTranslation();

  return (
    <Modal
      title={t("RELIQUATS / EN ATTENTE")}
      open={open}
      onOk={onOk}
      closable={true}
      onCancel={onOk}
      footer={[
        <Button key="back" onClick={onOk}>
          {t("Fermer")}
        </Button>,
      ]}
      width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {articlesReliquat && articlesReliquat.length > 1
            ? "Articles"
            : "Article"}
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "right" }}>
          {t("Qté")}
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
          Ref. Cmd
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
          Date
        </Col>
      </Row>
      <Divider style={{ marginTop: "12px" }} />
      <Row>
        {articlesReliquat && articlesReliquat.length > 0
          ? articlesReliquat?.map((bon) => (
              <Fragment key={bon?.Id}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>{bon.Designation}</p>
                  </div>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  <div style={{ textAlign: "right" }}>
                    <p>{bon.Quantite}</p>
                  </div>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div style={{ textAlign: "right" }}>
                    <p>{bon.NumeroDocument}</p>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div style={{ textAlign: "right" }}>
                    <p>
                      {
                        new Date(
                          parseInt(bon?.Date?.split("(")[1]?.split(")")[0])
                        )
                          .toLocaleString()
                          .split(" ")[0]
                      }
                    </p>
                  </div>
                </Col>
                <Divider />
              </Fragment>
            ))
          : articlesReliquat?.map((art) => art.Designation)}
      </Row>
    </Modal>
  );
};

export default ModalReliquat;
