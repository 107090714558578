import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MainContext from "../../contexts/MainContext";
import {
  // DashboardClientBestSeller,
  DashboardClientCA,
  DashboardClientHistoriqueCommandes,
  DashboardClientImpaye,
  DashboardClientInfosClient,
  DashboardClientReliquats,
  DashboardClientTotalDu,
  DashboardClientVolume,
} from "../../components";
import { Col, Row } from "antd";
import { LeftCircleFilled } from "@ant-design/icons";

const DetailsClient = () => {
  const {
    getClientById,
    getFacturesByClient,
    getBonsCommandeByClient,
    getVolumeByClient,
    clientById,
    factures,
    bonsCommande,
    totalVolumeByClient,
    isLoadingVolumeClient,
    getTotalFacturesImpayesByClientN,
    getTotalFacturesImpayesByClientNMoinsUn,
    totalFacturesImpayesByClientN,
    totalFacturesImpayesByClientNMoinsUn,
    getTotalDuByClient,
    totalDuClient,
  } = useContext(MainContext);
  const [factureAnneeEnCours, setFactureAnneeEnCours] = useState([]);
  const [factureAnneeMoinsUn, setFactureAnneeMoinsUn] = useState([]);
  const [dateDerniereCommande, setDateDerniereCommande] = useState();
  // const [montantImpaye, setMontantImpaye] = useState(null);
  // const [tabFacturesImpayes, setTabFacturesImpayes] = useState([]);

  const navigate = useNavigate();
  const { idClient } = useParams();
  const dateNow = new Date();

  useEffect(() => {
    getClientById(idClient);
    getFacturesByClient(idClient);
    getBonsCommandeByClient(idClient);
    getVolumeByClient(idClient);
    getTotalFacturesImpayesByClientN(idClient);
    getTotalFacturesImpayesByClientNMoinsUn(idClient);
    getTotalDuByClient(idClient);
  }, [idClient]);

  useEffect(() => {
    triFactureParAn(factures);
  }, [factures]);

  /**TRI LES FACTURES PAR AN, SET LE MONTANT IMPAYE ET LA DATE DE LA DERNIERE COMMANDE
   * A DIVISER EN DE PLUS PETITES FONCTIONS
   */
  const triFactureParAn = (tab) => {
    // let montantImpayeTmp = 0;
    let lastFactureTmp = 0;
    let tabAnneeEnCours = [];
    let tabAnneeMoinsUn = [];
    // let detailsFacturesImpayesTmp = [];

    tab.forEach((facture) => {
      const dateTimestamp = parseInt(
        facture.DateCreation.split("(")[1].split(")")[0].split("+")[0]
      );

      if (dateTimestamp > lastFactureTmp) lastFactureTmp = dateTimestamp;

      const dateFacture = new Date(dateTimestamp);
      const anneeFacture = dateFacture.getFullYear();

      if (anneeFacture === dateNow.getFullYear()) {
        tabAnneeEnCours.push(facture);
      }
      if (anneeFacture === dateNow.getFullYear() - 1) {
        tabAnneeMoinsUn.push(facture);
      }
      // A remplacer
      // if (facture.MontantRegle !== facture.TotalTTC) {
      //   detailsFacturesImpayesTmp.push(facture);
      //   montantImpayeTmp += facture.TotalTTC - facture.MontantRegle;
      // }
    });
    // console.log("tab factures impayes: ", detailsFacturesImpayesTmp);
    // setTabFacturesImpayes(detailsFacturesImpayesTmp);
    // setMontantImpaye(montantImpayeTmp);
    setDateDerniereCommande(new Date(lastFactureTmp));
    setFactureAnneeEnCours(tabAnneeEnCours);
    setFactureAnneeMoinsUn(tabAnneeMoinsUn);
  };

  if (clientById === "offline") {
    return (
      <div
        style={{
          padding: "0 0 8px 0",
          position: "sticky",
          top: "68px",
          zIndex: "2",
        }}>
        <LeftCircleFilled
          style={{ fontSize: "32px", color: "var(--primary-color)" }}
          onClick={() => navigate(-1)}
        />
        <h1>Pas de connexion internet, cette page ne peut pas être affichée</h1>
      </div>
    );
  }

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          padding: "0 0 8px 0",
          position: "sticky",
          top: "68px",
          zIndex: "2",
        }}>
        <LeftCircleFilled
          style={{ fontSize: "32px", color: "var(--primary-color)" }}
          onClick={() => navigate(-1)}
        />
      </div>
      <Row gutter={[16, 16]} align="center" justify="center" style={{ height: "90%" }}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <DashboardClientInfosClient
            clientById={clientById}
            dateDerniereCommande={dateDerniereCommande}
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Row gutter={[16, 16]} align="center" justify="center" style={{ height: "100%" }}>
            <DashboardClientCA
              factureAnneeEnCours={factureAnneeEnCours}
              factureAnneeMoinsUn={factureAnneeMoinsUn}
            />
            <DashboardClientVolume
              statusClient={clientById?.Statistique02}
              totalVolumeByClient={totalVolumeByClient}
              isLoadingVolumeClient={isLoadingVolumeClient}
            />
            <DashboardClientHistoriqueCommandes bonsCommande={bonsCommande} factures={factures} />
          </Row>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <DashboardClientReliquats />
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <DashboardClientImpaye
            montantImpayeN={totalFacturesImpayesByClientN.montantTotalImpaye}
            tabFacturesImpayesN={totalFacturesImpayesByClientN.facturesImpayes}
            montantImpayeNMoinsUn={totalFacturesImpayesByClientNMoinsUn.montantTotalImpaye}
            tabFacturesImpayesNMoinsUn={totalFacturesImpayesByClientNMoinsUn.facturesImpayes}
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <DashboardClientTotalDu
            montantTotalDu={totalDuClient.montantTotalDu}
            tabFactures={totalDuClient.factures}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DetailsClient;
