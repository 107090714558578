import { Input } from "antd";
import "./search-bar.css";
import SearchContext from "../../contexts/SearchContext";
import { useContext } from "react";

const SearchBar = ({ onSearch }) => {
  const { searchTextProducts } = useContext(SearchContext);
  return (
    <div className="search-bar-container">
      <Input.Search
        placeholder="Votre recherche"
        defaultValue={searchTextProducts || null}
        onSearch={onSearch}
        size="large"
        enterButton
        allowClear
      />
    </div>
  );
};

export default SearchBar;
